import gql from 'graphql-tag';
export const PriceFragmentDoc = gql`
    fragment Price on MoneyV2 {
  amount
  currencyCode
}
    `;
export const ShopifyImageFragmentDoc = gql`
    fragment ShopifyImage on Image {
  id
  url
}
    `;
export const SellingPlanFragmentDoc = gql`
    fragment SellingPlan on SellingPlan {
  checkoutCharge {
    type
    value
  }
  description
  id
  name
  options {
    name
    value
  }
  priceAdjustments {
    adjustmentValue {
      ... on SellingPlanPercentagePriceAdjustment {
        adjustmentPercentage
      }
    }
    orderCount
  }
  recurringDeliveries
}
    `;
export const SellingPlanGroupFragmentDoc = gql`
    fragment SellingPlanGroup on SellingPlanGroup {
  appName
  name
  options {
    name
    values
  }
  sellingPlans(first: 10) {
    nodes {
      ...SellingPlan
    }
  }
}
    ${SellingPlanFragmentDoc}`;
export const ProductVariantFragmentDoc = gql`
    fragment ProductVariant on ProductVariant {
  availableForSale
  currentlyNotInStock
  description: metafield(namespace: "nimbus", key: "description") {
    value
  }
  id
  image {
    ...ShopifyImage
  }
  price {
    ...Price
  }
  product {
    id
    title
  }
  quantityAvailable
  secondaryImages: metafield(namespace: "nimbus", key: "secondary_images") {
    value
  }
  selectedOptions {
    name
    value
  }
  sku
  title
}
    ${ShopifyImageFragmentDoc}
${PriceFragmentDoc}`;
export const ProductFragmentDoc = gql`
    fragment Product on Product {
  availableForSale
  collections(first: 1) {
    nodes {
      handle
      id
      title
    }
  }
  compareAtPriceRange {
    maxVariantPrice {
      ...Price
    }
  }
  createdAt
  updatedAt
  description
  descriptionHtml
  handle
  id
  images(first: 1) {
    nodes {
      ...ShopifyImage
    }
  }
  isSampler: metafield(namespace: "nimbus", key: "is_sampler") {
    value
  }
  isStarterKit: metafield(namespace: "nimbus", key: "is_starter_kit") {
    value
  }
  options {
    name
    values
  }
  priceRange {
    maxVariantPrice {
      ...Price
    }
    minVariantPrice {
      ...Price
    }
  }
  productType
  publishedAt
  sellingPlanGroups(first: 10) {
    nodes {
      ...SellingPlanGroup
    }
  }
  tags
  title
  totalInventory
  totalLitres: metafield(namespace: "nimbus", key: "total_litres") {
    value
  }
  variants(first: 5) {
    nodes {
      ...ProductVariant
    }
  }
}
    ${PriceFragmentDoc}
${ShopifyImageFragmentDoc}
${SellingPlanGroupFragmentDoc}
${ProductVariantFragmentDoc}`;
export const CartLineFragmentDoc = gql`
    fragment CartLine on BaseCartLine {
  attributes {
    key
    value
  }
  cost {
    amountPerQuantity {
      ...Price
    }
    subtotalAmount {
      ...Price
    }
    totalAmount {
      ...Price
    }
  }
  id
  merchandise {
    ... on ProductVariant {
      id
      image {
        ...ShopifyImage
      }
      product {
        ...Product
      }
      sku
      title
    }
  }
  quantity
  sellingPlanAllocation {
    priceAdjustments {
      unitPrice {
        amount
      }
    }
    sellingPlan {
      description
      id
      name
      options {
        name
        value
      }
    }
  }
}
    ${PriceFragmentDoc}
${ShopifyImageFragmentDoc}
${ProductFragmentDoc}`;
export const CartFragmentDoc = gql`
    fragment Cart on Cart {
  attributes {
    key
    value
  }
  buyerIdentity {
    countryCode
    email
    phone
  }
  checkoutUrl
  cost {
    subtotalAmount {
      ...Price
    }
    totalAmount {
      ...Price
    }
    totalTaxAmount {
      ...Price
    }
  }
  id
  lines(first: 20) {
    nodes {
      ...CartLine
    }
  }
}
    ${PriceFragmentDoc}
${CartLineFragmentDoc}`;
export const CollectionFragmentDoc = gql`
    fragment Collection on Collection {
  description
  handle
  id
  products(first: 10, sortKey: MANUAL) {
    nodes {
      ...Product
    }
  }
  title
}
    ${ProductFragmentDoc}`;
export const AddressFragmentDoc = gql`
    fragment Address on MailingAddress {
  address1
  address2
  city
  company
  country
  id
  province
  provinceCode
  zip
}
    `;
export const CustomerFragmentDoc = gql`
    fragment Customer on Customer {
  createdAt
  defaultAddress {
    ...Address
  }
  displayName
  email
  firstName
  id
  lastName
  phone
  updatedAt
}
    ${AddressFragmentDoc}`;
export const CartBuyerIdentityUpdateDocument = gql`
    mutation CartBuyerIdentityUpdate($cartId: ID!, $buyerIdentity: CartBuyerIdentityInput!) {
  cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
    cart {
      ...Cart
    }
    userErrors {
      code
      field
      message
    }
  }
}
    ${CartFragmentDoc}`;
export const CartCreateDocument = gql`
    mutation CartCreate($input: CartInput) {
  cartCreate(input: $input) {
    cart {
      ...Cart
    }
    userErrors {
      code
      field
      message
    }
  }
}
    ${CartFragmentDoc}`;
export const CartLinesAddDocument = gql`
    mutation CartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      ...Cart
    }
    userErrors {
      code
      field
      message
    }
  }
}
    ${CartFragmentDoc}`;
export const CartLinesRemoveDocument = gql`
    mutation CartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
      ...Cart
    }
    userErrors {
      code
      field
      message
    }
  }
}
    ${CartFragmentDoc}`;
export const CartLinesUpdateDocument = gql`
    mutation CartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
  cartLinesUpdate(cartId: $cartId, lines: $lines) {
    cart {
      ...Cart
    }
    userErrors {
      code
      field
      message
    }
  }
}
    ${CartFragmentDoc}`;
export const CustomerAccessTokenCreateDocument = gql`
    mutation CustomerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
  customerAccessTokenCreate(input: $input) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    `;
export const CustomerActivateByUrlDocument = gql`
    mutation customerActivateByUrl($activationUrl: URL!, $password: String!) {
  customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
    customer {
      ...Customer
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    ${CustomerFragmentDoc}`;
export const CustomerAddressCreateDocument = gql`
    mutation CustomerAddressCreate($address: MailingAddressInput!, $customerAccessToken: String!) {
  customerAddressCreate(
    address: $address
    customerAccessToken: $customerAccessToken
  ) {
    customerAddress {
      ...Address
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    ${AddressFragmentDoc}`;
export const CustomerAddressUpdateDocument = gql`
    mutation CustomerAddressUpdate($address: MailingAddressInput!, $customerAccessToken: String!, $id: ID!) {
  customerAddressUpdate(
    address: $address
    customerAccessToken: $customerAccessToken
    id: $id
  ) {
    customerAddress {
      ...Address
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    ${AddressFragmentDoc}`;
export const CustomerCreateDocument = gql`
    mutation CustomerCreate($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    customer {
      ...Customer
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    ${CustomerFragmentDoc}`;
export const CustomerDefaultAddressUpdateDocument = gql`
    mutation CustomerDefaultAddressUpdate($addressId: ID!, $customerAccessToken: String!) {
  customerDefaultAddressUpdate(
    addressId: $addressId
    customerAccessToken: $customerAccessToken
  ) {
    customer {
      ...Customer
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    ${CustomerFragmentDoc}`;
export const CustomerRecoverDocument = gql`
    mutation CustomerRecover($email: String!) {
  customerRecover(email: $email) {
    customerUserErrors {
      code
      field
      message
    }
  }
}
    `;
export const CustomerResetByUrlDocument = gql`
    mutation CustomerResetByUrl($password: String!, $resetUrl: URL!) {
  customerResetByUrl(password: $password, resetUrl: $resetUrl) {
    customer {
      ...Customer
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    ${CustomerFragmentDoc}`;
export const CustomerUpdateDocument = gql`
    mutation CustomerUpdate($customer: CustomerUpdateInput!, $customerAccessToken: String!) {
  customerUpdate(customer: $customer, customerAccessToken: $customerAccessToken) {
    customer {
      ...Customer
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    ${CustomerFragmentDoc}`;
export const GetCartDocument = gql`
    query GetCart($id: ID!) {
  cart(id: $id) {
    ...Cart
  }
}
    ${CartFragmentDoc}`;
export const GetCollectionDocument = gql`
    query GetCollection($handle: String!) {
  collection(handle: $handle) {
    ...Collection
  }
}
    ${CollectionFragmentDoc}`;
export const GetCollectionsDocument = gql`
    query GetCollections {
  collections(first: 10) {
    nodes {
      handle
      products(first: 10) {
        nodes {
          handle
          updatedAt
        }
      }
      updatedAt
    }
  }
}
    `;
export const GetCustomerDocument = gql`
    query GetCustomer($customerAccessToken: String!) {
  customer(customerAccessToken: $customerAccessToken) {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;
export const GetMediaImageDocument = gql`
    query GetMediaImage($id: ID!) {
  node(id: $id) {
    ... on MediaImage {
      id
      image {
        id
        url
      }
    }
  }
}
    `;
export const GetProductByHandleDocument = gql`
    query GetProductByHandle($handle: String!) {
  productByHandle(handle: $handle) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;
export const GetProductByIdDocument = gql`
    query GetProductById($id: ID!) {
  product(id: $id) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;